import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Scroll } from 'vuetify/lib/directives';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes:{
            dark:{
                primary: '#c0181e',
                secondary: '#ab191d',
                accent: '#d0131f'
            }
        }
    },
    directives: {
        Scroll
    }
});
