<template>
  <v-navigation-drawer app
                       v-model="drawer"
                       fixed
                       temporary
                       dark
                       color="grey darken-4"
  >
    <v-list rounded class="nav-title">
      <v-list-item
          v-for="language in languages"
          :key="language.title"
          @click="changeLocale(language.language)"
      >
        <v-list-item-title>
          <flag :iso="language.flag" v-bind:squared="false"/>
          {{ language.title }}
        </v-list-item-title>
      </v-list-item>
      <v-divider
          class="my-6"
      />
      <v-list-item
          v-for="(link, i) in links"
          :key="i"
          :to="link[0]"
      >
        <v-list-item-content class="d-flex justify-center">
          <v-list-item-title>
            {{ $t(`toolbar.${link[1]}`) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider
          class="my-6"
      />
      <v-list-item to="/imprint">
        <v-list-item-content>
          <v-list-item-title v-text="$t('imprint.imprint')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/imprint">
        <v-list-item-content>
          <v-list-item-title v-text="$t('imprint.privacy')"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapMutations,
  mapState
} from 'vuex'
import i18n from '@//plugins/i18n'

export default {
  computed: {
    ...mapState('app', ['links']),
    ...mapState('app', ['languages']),
    drawer: {
      get() {
        return this.$store.state.app.drawer
      },
      set(val) {
        this.setDrawer(val)
      }
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
    changeLocale(locale) {
      i18n.locale = locale
      this.$eventHub.$emit('locale-changed')
    },
  }
}
</script>

<style lang="sass" scoped>
@import 'src/styles/variables.scss'

.v-application
  .nav-title
    font-family: $logoLight
</style>
