<template>
  <v-app class="application" dark>
    <core-toolbar />

    <core-drawer />

    <core-view />

    <core-footer />
  </v-app>
</template>

<script>
    export default {
        components: {
            CoreDrawer: () => import('@/components/core/Drawer'),
            CoreFooter: () => import('@/components/core/Footer'),
            CoreToolbar: () => import('@/components/core/Toolbar'),
            // CoreTimeline: () => import('@/components/core/Timeline'),
            CoreView: () => import('@/components/core/View')
        }
    }
</script>

<style lang="sass">
@import 'src/styles/variables.scss'
@import "@/assets/BinarioSoft-LightOblique.scss"
@import "@/assets/BinarioSoft-Oblique.scss"

.logoLight
  font-family: $logoLight
  font-weight: normal
  font-style: oblique

.logo
  font-family: $logo
  font-weight: bold
  font-style: oblique

.application
  background: white !important

#hero h1
  letter-spacing: 4px !important

::-webkit-scrollbar
  width: 10px


::-webkit-scrollbar-track
  background: transparent

</style>
