<template>
    <div id="view">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'CoreView',
    }
</script>


