import { set, toggle } from '@/utils/vuex'


export default {
    namespaced: true,

    state: {
        currentLink: 0,
        drawer: null,
        links: [
            ['/', 'home', [['#aspects', 'aspects'],['#about', 'about'], ['#partners','partners'],['#news','news']]],
            ['/about', 'about', [['#about','about'],['#executives','executives']]],
            ['/agenda', 'agenda'],
            ['/sponsors', 'sponsors'],
            // ['/news', 'news'],
            // ['/teams', 'teams',[['#youth','youth'],['#women','women'],['#men','men'],['#prowomen','proWomen'],['#promen','proMen']]],
            // ['/youth', 'youth',[['#youth','youth'],['#women','women'],['#men','men'],['#prowomen','proWomen'],['#promen','proMen']]],
            ['/training', 'workouts',[['#workouts', 'workouts'],['#facilities','facilities'],['#coaches','coaches']]],
            ['/contact', 'contact', [['#contact','contact'],['#document','document']]]
        ],
        languages: [
            { flag: 'de', language: 'de', title: 'Deutsch' },
            { flag: 'gb', language: 'en', title: 'English' },
        ],
    },
    mutations: {
        setDrawer: set('drawer'),
        toggleDrawer: toggle('drawer'),
        setLink (state, value) {state.currentLink = value},
    },
    actions: {
        setLink({commit}, value) {commit('setLink', value)},
    }
}
