<template>
    <v-app-bar app
               v-scroll="onScroll"
               :color="color"
               :dark="hasColor"
               :light="hasColor"
               elevate-on-scroll
               height="100"
    >
        <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="toggleDrawer"
        />

        <v-spacer class="hidden-sm-and-up" />

        <v-toolbar-title class="logoLight">
            <template v-if="$vuetify.breakpoint.smAndUp">
        <span>
        {{$t('toolbar.toolBarTitle')}}
        </span>
            </template>
            <span v-else>
        KTT 01
      </span>
        </v-toolbar-title>

        <v-spacer class="hidden-xs-only" />

        <v-btn
                v-for="(link, i) in links"
                :key="i"
                :to="link[0]"
                class="font-weight-light hidden-sm-and-down"
                text
                large
        >
            {{$t(`toolbar.${link[1]}`)}}
        </v-btn>
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                        class="font-weight-light hidden-sm-and-down"
                        text
                        large
                        v-on="on"
                >
                    {{$t('toolbar.lang')}}
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                        v-for="language in languages"
                        :key="language.title"
                        @click="changeLocale(language.language)"
                >
                    <v-list-item-title>
                        <flag :iso="language.flag" v-bind:squared=false />
                        {{language.title}}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    // Utilities
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import i18n from '@//plugins/i18n'

    export default {
        data: () => ({
            hasColor: false
        }),

        computed: {
            ...mapState('app', ['links']),
            ...mapState('app',['languages']),
            color () {
                return this.hasColor ? 'rgba(0, 0, 0, 0.8)' : 'transparent'
            }
        },
        methods: {
            ...mapMutations('app', ['toggleDrawer']),
            changeLocale (locale) {
                i18n.locale = locale
                this.$eventHub.$emit('locale-changed')
            },
            onScroll () {
                this.hasColor = window.scrollY > 100
            }
        }
    }
</script>
