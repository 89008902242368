<template>
    <v-card
            class="mx-auto"
            flat
            tile
            light
            v-bind="$attrs"
    >
        <v-sheet
                class="mx-auto py-0"
        >
            <slot />
        </v-sheet>
    </v-card>
</template>

<script>
    export default {
        name: 'BaseCard',
    }
</script>

<style scoped>
</style>
