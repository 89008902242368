<template>
    <h1
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
            class="white--text logo"
    >
        <slot />
    </h1>
</template>

<script>
    export default {
        name: 'BaseTitle'
    }
</script>

<style lang="sass">
@import 'src/styles/variables.scss'

.v-application .display-3
  font-family: $logo
.v-application .headline
  font-family: $logoLight
</style>
